import React, { useEffect, useLayoutEffect,forwardRef,useContext, useState, useImperativeHandle, useRef } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
//import { loadStripe } from '@stripe/stripe-js';
import { APIGet, APIPost, APIRootDomain } from '../../API/APIRequest'
import { ErrorBox } from '../../Utilities/ErrorBox'
//import { useElements, useStripe, Elements, PaymentElement } from '@stripe/react-stripe-js';
import '../../../i18n'
import { PaymentFormContext, updateBillingAddress } from '../PaymentFormReducer';
//import * as signalR from '@microsoft/signalr';
import { Loading } from '../../Utilities/Loading';
import { gtmEventTriggers } from '../../Utilities/GtmFunctions';


export const WorldPayForm = forwardRef((props, ref) => {
    //const [notification, setNotification] = useState('');
    const {setPaymentMethodErrors}= props;
    const fid = props.basket.id; 
    const checkoutScriptUrl = "https://payments.worldpay.com/resources/hpp/integrations/embedded/js/hpp-embedded-integration-library.js";
    let checkout;
    var tries = 0;

    function scriptAlreadyLoaded(src) {
        return document.querySelector(`script[src="${src}"]`);
    }


    function loadCheckoutScript(src) {
        return new Promise((resolve, reject) => {
            if (scriptAlreadyLoaded(src)) {
                resolve();
                return;
            }
          let checkoutScript = document.createElement("script");
            checkoutScript.src = src;
            checkoutScript.onload = resolve;
            checkoutScript.onerror = reject;
            document.head.appendChild(checkoutScript);
        });
    }

    const addWorldpayCheckoutToPage = (props) =>{
    return new Promise((resolve, reject) => {
     
        //var handlerUrl = window.location.protocol + "//" + window.location.host + window.location.pathname.replace('payment', 'worldpayresponsehandler');
          //var WPCL = {};
            // Your custom options for injecting the iframe
            let customOptions = {
                url: props.hostedPageURL,
                type: 'iframe',
                inject: 'immediate',
                target: 'custom-html',
                language: 'en',
                country: 'gb',
                preferredPaymentMethod: 'ALL',
                accessibility: true,
                debug: false,
                /*
                successURL: handlerUrl + '/success',
                cancelURL:  handlerUrl + '/cancel',
                failureURL: handlerUrl + '/failure',
                pendingURL: handlerUrl + '/pending',
                errorURL: handlerUrl + '/error'
                */
                resultCallback: WorldpayCallback
               
            }
            // Initialize the library and pass options
            let libraryObject = new window.WPCL.Library();
            libraryObject.setup(customOptions);  
      
        });
    }

  
    const WorldpayCallback = (result) => {
    
        let redirect_url="";
        let status = result.order.status;
        switch (status) {
            case "success":
                setTimeout(WaitForNotification, 500);
                gtmEventTriggers("add_payment_info"); 
                console.log(window.dataLayer);
                break;
            case "failure":     // show error
                break;
            case "cancelled":    // user cancelled, reshow payment options
                break;
            case "error":       // show error
                break;
            default:
                break;
        }
    }

    const WaitForNotification = () => {
        tries = tries + 1;
        APIPost({
            "controller": "worldpay",
            "action": "checknotificationreceived",
            "environment": null,
            "identifier": fid
        }).then((response) => {
            OnWorldpayNotification(response.data.status);
        }).catch((error) => {

        });
    }

    const OnWorldpayNotification = (status) => {
        switch (status) {
            case "OK":
                let handlerUrl = window.location.protocol + "//" + window.location.host + "/WorldpayResponseHandler/" + fid +  window.location.search;
                window.location.href = handlerUrl; // success -> 
                break;
            case "PENDING":
                if (tries < 240) {
                    setTimeout(WaitForNotification, 500);
                }
                else {
                    alert("Timed out waiting for worldpay notification.")
                }
                break;
            case "CANCELLED":    // user cancelled, reshow payment options
                break;
            default:
                break;
        }
    }
    
    const InitialiseWorldPay = (props) => {
        return new Promise((resolve, reject) => {
            let basket = props.basket;
            const data = new FormData();
            //string currencyCode, string amountWithoutDecimalSeperator ,string amountExponent, string shopperEmail
            data.append("fid", basket.id);
            data.append("paymentTypeId", props.paymentSelection);
            APIPost({
                "controller": "worldpay",
                "action": "gethostedpageurl",
                "environment": null,
                "data": data
            })
                .then((response) => {
                    let hosteduri = response.data.worldpayurl;
                    let wpResult = {
                        //"promise": promise,
                        "hostedUrl": hosteduri
                    };
                    resolve(wpResult);

                    //'https://secure-test.worldpay.com/jsp/shopper/SelectPaymentMethod.jsp?OrderKey=ABACUSTESTECOM%5Eorder001u0zzdd0d1d6ss6wfts0s0eb212f233s'
                    //addWorldpayCheckoutToPage(hosteduri)
                    //    .then((res) => {
                    //        let wpResult = {
                    //            //"promise": promise,
                    //            "hostedUrl": hosteduri
                    //        };
                    //        resolve(wpResult);
                    //    })
                    //    .catch(console.warn);

                }).catch((error) => {
                    reject(error);
                });
            
        });
    }

    useEffect(() => {
       
        loadCheckoutScript(checkoutScriptUrl)
            .then((rr) => {
                InitialiseWorldPay({ "paymentSelection": props.paymentTypeId, "basket": props.basket }).then((wpResult) => {
                 
                   
                    let uri = wpResult.hostedUrl;
                        //'https://secure-test.worldpay.com/jsp/shopper/SelectPaymentMethod.jsp?OrderKey=ABACUSTESTECOM%5Eorder001u0zzdd0d1d6ss6wfts0s0eb212f233s';
                    addWorldpayCheckoutToPage({ "basketid": props.basket.id, "hostedPageURL": uri})
                        .then((checkoutInstance) => {
                            //checkout = checkoutInstance;
                          
                        })
                        .catch((error) => {
                            let str = JSON.stringify(error, null, 4); 
                            console.log(str);
                            if(error.response?.status===500){
                                setPaymentMethodErrors("500_errors")
                            }
                            /* added */
                        });

                }).catch((error)=>{
                    let str = JSON.stringify(error, null, 4);
                    console.log(str);
                    if(error.response?.status===500){
                        setPaymentMethodErrors("500_errors")
                    }
                  }
                  
                )

                //let r = 1;
            })
            .catch(console.warn);

        /***************************SignalR**************************************/
/*
        const connection = new signalR.HubConnectionBuilder()
            .withUrl(APIRootDomain()+"/notificationHub", {
                //skipNegotiation: true,
                //transport: signalR.HttpTransportType.WebSockets
            }) 
            .withAutomaticReconnect()
            .configureLogging(signalR.LogLevel.Information)
            .build();
        const sessionGuid = fid;

        connection.start()
            .then(() => {
             
                // Now you can send data
                if (connection.state === signalR.HubConnectionState.Connected) {
                    connection.on('ReceiveNotification', (bId, wpToken, oId) => {
                          
                            WorldpayCallback({ order: { status: "AbacusOrderNotificationTokenReceived", tokenInfo: wpToken, orderId: oId} });
                            
                            //setNotification(bId);
                    });

                    connection.invoke('JoinSessionGroup', sessionGuid)
                        .catch(error => console.error('Error sending data:', error));

                } else {
                    console.warn('SignalR connection is not in the "Connected" state. Waiting...');
                }
            })
            .catch(error => console.error('Error starting SignalR connection:', error));
     */
        return () =>
        {
            /*
                // Leave the SignalR group when the component unmounts
                connection.invoke('LeaveSessionGroup', sessionGuid).catch(err => console.error(err));
                connection.stop().catch(err => console.error(err));
            */
        };
    }, []);
    
//     useImperativeHandle(ref, () => ({
//         confirmPayment() {
//             APIPost({
//                 "controller": "worldpay",
//                 "action": "processpayment",
//                 "environment": null,
//                 "identifier": fid
//             }).then((response) => {
//                 window.location.href = window.location.protocol + "//" + window.location.host + "/thankyou/" + response.data.OrderId;
//             }).catch((error) => {
//                 throw (error);
//             });
//         },
//         errors
//     }
// ));
    
    //useLayoutEffect(() => {
    //    // Make sure to call the remove method (once) in order to deallocate the SDK from memory
    //    return () => checkout.remove();
    //},
    //    []);

   
    return (
        <>  
          
           <div id="custom-html"></div>     
        
        </>
    );
});



